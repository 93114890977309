.App {
  display: flex;
  height: 100%;
  justify-content: center;
}

#header {
  justify-content: center;
}

.suggestion-wrapper {
  background-color: #fff;
  position: absolute;
  padding: 2px 15px;
  text-align: start;
  z-index: 1;
}

.none {
  visibility: hidden;
}

.suggestions {
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 4px;
}

.suggestions:hover {
  background-color: #6c757d;
  color: #fff;
  cursor: pointer;
}

.mod {
  position: relative;
  width: 100%;
  max-width: 50em;
  min-width: 15em;
}

.output-name {
  font-style: italic;
  float: right;
}

.footer {
  position: fixed;
  left:0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

.footer-element {
  float: right;
  cursor: pointer;
  max-height: 2em;
  max-width: 2em;
}

.github-img {
  height: 100%;;
  width: 100%;
}
